import React, { Component } from 'react'

import './sign-in-out.scss'

const SignInOutNavbar = class extends Component {
  render() {
    if (!this.props.authenticated) {
      return (
          <div
            onClick={this.props.signin.bind(this)}
            className="nav-link"
          >
            Teacher Login
          </div>
      )
    } else if (this.props.authenticated) {
      return (
          <div
            onClick={this.props.signout.bind(this)}
            className="nav-link"
          >
            Teacher Logout
          </div>
      )
    }
  }
}

export default SignInOutNavbar
