let auth0package = null
let navigate = null
// if (typeof window !== `undefined`) {
//   // import auth0 from 'auth0-js'
//   const auth0package = require('auth0-js')
// }

// import { navigate } from 'gatsby'

const AUTH0_DOMAIN = 'bscs-science-learning.auth0.com'
const AUTH0_CLIENT_ID = 'gK05NYFaUtrTZJx6fNx1uZZQuw0bPKiq'

let Auth

if (typeof window !== `undefined`) {
  auth0package = require('auth0-js')
  navigate = require('gatsby').navigate

  try {
    if (typeof window !== `undefined`) {
      var origin = window.location.origin
    }
  } catch(error) {
    console.log(error)
  }

Auth = class Auth {
  // var Auth = class Auth {

    auth0 = new auth0package.WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      // redirectUri: 'http://localhost:8000/callback',
      redirectUri: `${origin}/callback`,
      // redirectUri: `${origin}/`,
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      responseType: 'token id_token',
      scope: 'openid profile email'
    })

    constructor() {
      try {
        this.signin = this.signin.bind(this)
        this.signout = this.signout.bind(this)
        this.handleAuthentication = this.handleAuthentication.bind(this)
        this.isAuthenticated = this.isAuthenticated.bind(this)
      } catch(e) {
        console.log(e);
      }

    }

    signin() {
      // Set the path to return user to after login
      try {
        if (typeof window !== `undefined`) {
          localStorage.setItem('previousPath', window.location.pathname);
          this.auth0.authorize();
        }
      } catch(e) {
        console.log(e);
      }
    }

    signout() {
      try {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user');
        localStorage.removeItem('courses');
        // window.location.replace(`https://bscs-science-learning.auth0.com/v2/logout?returnTo=http://localhost:8000/&client_id=${AUTH0_CLIENT_ID}`)
        if (typeof window !== `undefined`) {
          window.location.replace(`https://bscs-science-learning.auth0.com/v2/logout?returnTo=${origin}/&client_id=${AUTH0_CLIENT_ID}`)
        }
      } catch(e) {
        console.log(e);
      }

      // If you are using Facebook, please be aware of the additional requirements when triggering a logout.

      // You will also need to encode the returnTo parameter.
      // window.location.replace(`https://bscs-science-learning.auth0.com/v2/logout?federated&
      //   returnTo=https%3A%2F%2Fbscs-science-learning.auth0.com%2Flogout%3FreturnTo%3Dhttp%3A%2F%2Fwww.example.com
      //   &access_token=[facebook access_token]`)
    }

    handleAuthentication() {
      try {
        if (typeof window !== `undefined`) {
          this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
              this.setSession(authResult)
            } else if (err) {
              console.log(err)
            }

            // Return to previous page after authentication.
            const previousPath = localStorage.getItem('previousPath')
            navigate(previousPath)
            localStorage.removeItem('previousPath')
          });
        }
      } catch(e) {
        console.log(e);
      }
    }

    isAuthenticated() {
      try {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
        return new Date().getTime() < expiresAt
      } catch(e) {
        console.log(e);
      }
    }

    setSession(authResult) {
      try {
        if (typeof window !== `undefined`) {
          window.dispatchEvent(new Event('storage'));
          const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
          localStorage.setItem('access_token', authResult.accessToken)
          localStorage.setItem('id_token', authResult.idToken)
          localStorage.setItem('expires_at', expiresAt)

          this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
            localStorage.setItem('user', JSON.stringify(user))
          })
        }
      } catch(e) {
        console.log(e);
      }
    }

    getUser() {
      try {
        if (localStorage.getItem('user')) {
          return JSON.parse(localStorage.getItem('user'))
        }
      } catch(e) {
        console.log(e);
      }
    }

    getUserName() {
      if (this.getUser()) {
        return this.getUser().name;
      }
    }
  }
}

// module.exports = Auth
// exports.default = Auth
export default Auth