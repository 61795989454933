import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'

import './footer.scss'

import bscsLogo from '../../../images/logos/bscs_logo.svg'
import opbLogo from '../../../images/logos/opb_logo.svg'
import nasaLogo from '../../../images/logos/nasa_logo.svg'

import SignInOutFooter from '../../auth/sign-in-out-footer'


export default class Footer extends Component {
  render() {
    const renderMenu = <StaticQuery
      query={graphql`
        query navigationQuery{
          allNavigationJson {
            edges {
              node {
                title,
                path,
                onClick
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="d-flex">
            <Nav>
              {data.allNavigationJson.edges.map((edge, index) => {
                return(
                  <Nav.Item key={`title-${index}`}>
                    {
                      edge.node.onClick > ""
                      ?
                        <div
                          onClick={this.props.launchGeneral}
                          className="nav-link text-info text-light footer-contact-button"
                        >
                          {edge.node.title}
                        </div>
                      :
                        edge.node.title === "Teacher Resources"
                        ?
                          !this.props.authenticated
                          ?
                            <></>
                          :
                          <Link
                            className={
                              this.props.location === edge.node.path ? "nav-link active disabled text-info text-light" : "nav-link text-info text-light"
                            }
                            to={edge.node.path}
                          >
                            {edge.node.title}
                          </Link>
                        :
                        <Link
                          className={
                            this.props.location === edge.node.path ? "nav-link active disabled text-info text-light" : "nav-link text-info text-light"
                          }
                          to={edge.node.path}
                        >
                          {edge.node.title}
                        </Link>
                    }
                  </Nav.Item>
                )
              })}
              <SignInOutFooter
                signin={this.props.signin}
                signout={this.props.signout}
                authenticated={this.props.authenticated}
              />
            </Nav>
          </div>
        )
      }}
    />

    return (
      <footer>
        <Container fluid className="footerStyle">
          <Row className="justify-content-center pb-5">
            { renderMenu }
          </Row>
          <Row className="justify-content-center">
            <Col md={4}>
                <div className="footerBox d-flex justify-content-around">
                  <img
                    className="img-fluid footerImage bscsLogoFooter"
                    src={ bscsLogo }
                    alt="BSCS Science Learning logo"
                  />
                  <img
                    className="img-fluid footerImage opbLogoFooter"
                    src={ opbLogo }
                    alt="Oregon public broadcasting logo"
                  />
                  <img
                    className="img-fluid nasaLogoFooter"
                    src={ nasaLogo }
                    alt="NASA logo"
                    style={{ maxWidth: '75px' }}
                  />
                </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light text-center copyrightStyle">
                Copyright © BSCS Science Learning, {new Date().getFullYear()}. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}
