import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from './header/header'
import Footer from './footer/footer'
import Navbar from './navbar/navbar'
import GeneralContactFormModal from '../../components/modal/general_contact_form_modal'
import sideBarTe from "./sidemenu/sidemenu_te"

import '../../global-scss/index.scss'

import Auth from '../../auth/auth'

let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}


const Layout = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: undefined,
      modalShowGeneral: false
    }
  }

  componentDidMount() {
    const user = auth.getUser()
    if (user) {
      this.setState({user_email: user.email, authenticated: auth.isAuthenticated()})
    } else {
      this.setState({ authenticated: auth.isAuthenticated() })
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.launchContactModal && prevProps.launchContactModal !== this.props.launchContactModal) {
      this.setState({showContactModal: true})
    }
  }

  launchGeneral = () => { this.setState({modalShowGeneral: true}) }
  closeGeneral = () => {
    this.setState({modalShowGeneral: false})
    if(this.props.closeGeneral) {
      this.props.closeGeneral()
    }
  }

  signin = (e) => {
    e.preventDefault()
    auth.signin()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  signout = (e) => {
    e.preventDefault()
    auth.signout()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  render() {
   return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header />
          <Navbar
            location={this.props.location}
            launchGeneral={this.launchGeneral}
            authenticated={this.state.authenticated}
            signin={this.signin}
            signout={this.signout}
          />
          {this.props.children}
          <Footer
            location={this.props.location}
            launchGeneral={this.launchGeneral}
            authenticated={this.state.authenticated}
            signin={this.signin}
            signout={this.signout}
          />
          <GeneralContactFormModal
            show={this.state.modalShowGeneral}
            onHide={this.closeGeneral}
          />
        </>
      )}
    />

   )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
