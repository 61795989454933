import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import './navbar.scss'
import SignInOutNavbar from '../../auth/sign-in-out-navbar'

export default class Navbar1 extends Component {
  render() {
    const renderMenu = <StaticQuery
      query={graphql`
        query navigationQuery1{
          allNavigationJson {
            edges {
              node {
                title,
                path,
                onClick
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Navbar
              bg="nbcolor"
              expand="lg"
              className="d-none d-lg-block"
            >
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  { data.allNavigationJson.edges.map((edge, index) => {
                    return (
                      <React.Fragment key={`title-${index}`} >
                        {
                          edge.node.onClick > ""
                          ?
                            <div
                              onClick={this.props.launchGeneral}
                              className="nav-link"
                              id="responsive-navbar-nav2"
                            >
                              {edge.node.title}
                            </div>
                          :
                            edge.node.title === "Teacher Resources"
                            ?
                              !this.props.authenticated
                              ?
                                <></>
                              :
                                <Link
                                  className={
                                    this.props.location === edge.node.path ? "nav-link active disabled" : "nav-link"
                                  }
                                  to={edge.node.path}
                                >
                                  {edge.node.title}
                                </Link>
                            :
                            <Link
                              className={
                                this.props.location === edge.node.path ? "nav-link active disabled" : "nav-link"
                              }
                              to={edge.node.path}
                            >
                              {edge.node.title}
                            </Link>
                        }
                      </React.Fragment>
                    )
                  })}
                </Nav>
                <SignInOutNavbar
                  signin={this.props.signin}
                  signout={this.props.signout}
                  authenticated={this.props.authenticated}
                />
              </Navbar.Collapse>
            </Navbar>
            <Navbar
              bg="nbcolor"
              expand="lg"
              className="d-lg-none"
            >
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  { data.allNavigationJson.edges.map((edge, index) => {
                    return(
                      <React.Fragment key={`title-${index}`}>
                        {
                          edge.node.onClick > ""
                          ?
                          <div
                            onClick={this.props.launchGeneral}
                            className="nav-link"
                            id="responsive-navbar-nav2"
                          >
                            {edge.node.title}
                          </div>
                          :
                          edge.node.title === "Teacher Resources"
                          ?
                          !this.props.authenticated
                          ?
                          <></>
                          :
                          this.props.authenticated
                          ?
                          <Link
                            className={
                              this.props.location === edge.node.path ? "nav-link active disabled" : "nav-link"
                            }
                            to={edge.node.path}
                          >
                            {edge.node.title}
                          </Link>
                          :
                          <></>
                          :
                          <Link
                            className={
                              this.props.location === edge.node.path ? "nav-link active disabled" : "nav-link"
                            }
                            to={edge.node.path}
                          >
                            {edge.node.title}
                          </Link>
                        }
                      </React.Fragment>
                    )
                  })}
                  <SignInOutNavbar
                    signin={this.props.signin}
                    signout={this.props.signout}
                    authenticated={this.props.authenticated}
                  />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </>
        )
      }}
    />
    return (
      <>
        {renderMenu}
      </>
    )
  }
}
