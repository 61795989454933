import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'


const SideBarTe = class extends Component {
  render() {
    const renderMenu1 = <StaticQuery
      query={graphql`
        query navigationQuery5{
          allNavigationTeJson {
            edges {
              node {
                title,
                path
              }
            }
          }
        }
      `}
      render={data => {
        return(
          <>
            <div className="list-group sticky-top">
              { data.allNavigationTeJson.edges.map((edge, index) => {
                return (
                  <Link
                    className={
                      ( this.props.location === edge.node.path ) || ( this.props.location + '/' === edge.node.path ) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"
                    }
                    key={`title-${index}`}
                    to={edge.node.path}
                  >
                    {edge.node.title}
                  </Link>
                )
              })}
            </div>
          </>
        )
      }}
      />
      return (
        <>
          {renderMenu1}
        </>
      )
    }
}

export default SideBarTe
