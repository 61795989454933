import React, { Component } from 'react'

import './sign-in-out.scss'

const SignInOutFooter = class extends Component {
  render() {
    if (!this.props.authenticated) {
      return (
          <div
            onClick={this.props.signin.bind(this)}
            className="nav-link text-info text-light sign-in-out-footer"
          >
            Teacher Login
          </div>
      )
    } else if (this.props.authenticated) {
      return (
          <div
            onClick={this.props.signout.bind(this)}
            className="nav-link text-info text-light sign-in-out-footer"
          >
            Teacher Logout
          </div>
      )
    }
  }
}

export default SignInOutFooter
