import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './header.scss'

import ccsunlogo from '../../../images/header/carbon_connections_icon.svg'
import ccsun from '../../../images/header/cc_sun.svg'
import ccrays from '../../../images/header/cc_rays.svg'


export default function Header() {
  const bgBlack = {backgroundColor: '#000000'}
  return(
    <header style={bgBlack}>
      <Container
        style={{
          margin: 0,
          paddingTop: '1rem'
        }}
      >
        <Row>
          <Col md="auto" xs={6} md={4}>
              <img src={ccsun} alt="Carbon Connections Sun"
                style={{
                  width: '80px',
                  height: '80px',
                  marginBottom: '10px'
                }}
               id="sun"
              />
              <img src={ccrays} alt="Carbon Connections Rays"
                style={{
                  width: '200px',
                  height: '100px',
                  marginBottom: '0'
                }}
                id="rays"
              />
          </Col>
          <Col md="auto" xs={12} md={8}
            style={{
              marginTop: '1.25rem'
            }}
          >
            <div style={{ maxWidth: '410px' }}>
              <div style={{ width: '100%' }}>
                <Link to="/" className="text-decoration-none">
                  <div className="d-flex justify-content-center headerspace1">
                    <div className="d-flex align-items-left">
                      <div className="text-left">
                        <span className="logoText1"> carbon </span>
                          <img
                            src={ccsunlogo}
                            alt="Carbon Connections Logo"
                            style={{
                              width: '40px',
                              height: '40px',
                              marginBottom: '20px'
                            }}
                          />
                        <span className="logoText1"> connections </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-left">
                      <div className="text-left">
                        <span className="logoText2">The Carbon Cycle and the Science of Climate</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}
